* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Public Sans";
}

.formControlBox fieldset,
.userBox fieldset {
  border: none;
}

.formControlBox label {
  background: white;
  top: -5px;
  padding: 0 8px;
}
.formControlBox label.Mui-focused {
  color: rgba(0, 0, 0, 0.6);
}

.formControlBox .MuiSelect-select,
.inputBox input {
  padding: 11px;
}
